import React, { useState, useEffect, useContext } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { motion } from "framer-motion";
import TextField from "@mui/material/TextField";
import { Swiper, SwiperSlide } from "swiper/react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { MdOutlineClose, MdShoppingBasket } from "react-icons/md";
import ModalPhoto from "../components/product/ModalPhoto";
import MetaTag from "../components/metaTag";
import { textFieldSx } from "../components/product/mui.style";
import {
  previewContainer,
  previewImage,
  image,
  settingsElement,
  additionalSettings,
  toggle,
} from "../style/pages/shippingbox.module.scss";
import {
  detail,
  settingsContainer,
  titleSettings,
  priceInfo,
  mobCloseBtn,
  closeBtn,
  costBundle,
  detailHeader,
  settings,
  settingsBlock,
  productInfo,
  settingsList,
  orderInfo,
  productContainer,
  productItem,
  delProduct,
  mobileProducts,
  hint,
  nubmer,
} from "../components/product/product.module.scss";

import { StoreContext } from "../context/store-context";

function Shippingbox() {
  const imageData = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          relativeDirectory: { eq: "shippingbox" }
          name: { nin: ["main"] }
        }
        sort: { order: ASC, fields: name }
      ) {
        nodes {
          childImageSharp {
            id
            gatsbyImageData(
              breakpoints: [750, 1366, 1920]
              formats: [AUTO, AVIF, WEBP]
              quality: 85
              placeholder: DOMINANT_COLOR
            )
          }
        }
      }
    }
  `);

  const [selectedImg, setSelectedImg] = useState(null);

  const { store, setStore } = useContext(StoreContext);
  const [totalPrice, setTotalPrice] = useState(0);

  const [engravingText, setEngravingText] = useState("");
  const [quantity, setQuantity] = useState(0);

  const [totalAmount, setTotalAmount] = useState(0);

  const [mobileList, setMobileList] = useState(false);

  // Обновить состояние для текста гравировки
  const handleEngraving = (event) => {
    setEngravingText(event.target.value);
  };

  // Обновить состояние для количества
  const handleQuantity = (event) => {
    if (Number(event.target.value) >= 0) {
      setQuantity(Number(event.target.value));
    } else return;
  };

  // Добавить набор
  const addProduts = () => {
    const dataOrder = {
      quantity: quantity,
      engravingText: engravingText,
      totalAmount: totalAmount,
    };

    setTotalPrice(totalPrice + dataOrder.totalAmount);

    setStore({
      ...store,
      totalPrice: store.totalPrice + dataOrder.totalAmount,
      shippingbox: {
        bundle: [...store.shippingbox.bundle, dataOrder],
        totalPrice: store.shippingbox.totalPrice + dataOrder.totalAmount,
      },
      screen: { ...store.screens },
      acrylicfalsebeads: { ...store.acrylicfalsebeads },
      frame: { ...store.frame },
      toppers: { ...store.toppers },
      stand: { ...store.stand },
      spatulas: { ...store.spatulas },
      tag: { ...store.tag },
      sticks: { ...store.sticks },
      substrates: { ...store.substrates },
    });

    setQuantity(0);
    setEngravingText("");
    setTotalAmount(0);
  };

  // Удалить набор подложек
  const deleteProduct = (index) => {
    // Массив всех наборов
    const arrBundle = [...store.shippingbox.bundle];
    // Цена набора, который нужно удалить
    const deductibleAmount = store.shippingbox.bundle[index].totalAmount;

    // Удаляем из массива наборов элемент с заданным index
    arrBundle.splice(index, 1);
    setStore({
      ...store,
      totalPrice: store.totalPrice - deductibleAmount,
      shippingbox: {
        bundle: [...arrBundle],
        totalPrice: store.shippingbox.totalPrice - deductibleAmount,
      },
      screen: { ...store.screens },
      acrylicfalsebeads: { ...store.acrylicfalsebeads },
      frame: { ...store.frame },
      toppers: { ...store.toppers },
      stand: { ...store.stand },
      spatulas: { ...store.spatulas },
      tag: { ...store.tag },
      sticks: { ...store.sticks },
      substrates: { ...store.substrates },
    });
  };

  useEffect(() => {
    let q = quantity || 0;

    let e = engravingText ? q * 300 : 0;

    setTotalAmount(Math.ceil(q * 4200 + e));
  }, [quantity, engravingText]);

  return (
    <main className="mainpage">
      <MetaTag title="Короб для перевозки тортов | Wowmilota" />
      <motion.div layoutId="shippingboxContainer" className={detail}>
        {selectedImg && (
          <ModalPhoto
            selectedImg={selectedImg}
            setSelectedImg={setSelectedImg}
          />
        )}

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4 }}
          className={previewContainer}
        >
          {imageData?.allFile?.nodes.map((img, index) => {
            const { childImageSharp } = img;
            const src = getImage(img);

            return (
              <motion.div
                onClick={() => setSelectedImg(src)}
                onKeyDown={() => setSelectedImg(src)}
                className={previewImage}
                key={childImageSharp.id}
                whileHover={{ scale: 0.98 }}
              >
                <GatsbyImage
                  image={src}
                  alt="Фотография ширм амстердам"
                  className={image}
                />
              </motion.div>
            );
          })}
        </motion.div>

        <div className={settingsContainer}>
          <div className={settings}>
            <div className={settingsBlock}>
              <div className={productInfo}>
                <motion.div
                  className={detailHeader}
                  layout="position"
                  layoutId="shippingboxTitle"
                >
                  <h2>Короб для перевозки тортов</h2>
                </motion.div>
                <p>
                  Короб для торта предназначен для свадебных и многоярусных
                  тортов и является неотъемлемым инструментом для кондитеров во
                  время свадебного сезона.
                  <br />
                  <br />
                  Переноска для торта - хорошая помощь кондитеру в
                  транспортировке торта, прочная и долговечная конструкция,
                  которая выдерживает торты до 25 кг. Крепкая, надежная,
                  разборная, удобная в хранении, а также надежная для безопасной
                  доставки торта. Этот короб специально разработан для
                  безопасной и удобной транспортировки тортов различных размеров
                  и форм. Он предназначен для защиты торта от возможных
                  повреждений и обеспечивает удобство при транспортировке.
                  Переноска для торта обеспечивает надежную фиксацию и
                  предотвращает смещение торта и декора. Кроме того, модель
                  нашей коробки позволяет выбрать диаметр внутреннего
                  пространства, что способствует оптимальному подгону под
                  размеры конкретного торта и его надежной фиксации. Нами были
                  разработаны фиксаторы под разные диаметры от 24 до 40 см.
                  Также для максимального удобства в коробке предусмотрено
                  окошко из акрила для контроля за состоянием торта при
                  транспортировке.
                  <br />
                  <br />
                  Сделай свой свадебный сезон максимально удобным с нашей
                  коробкой для перевозки тортов от компании Wowmilota.
                </p>
              </div>

              <h3 className={titleSettings}>Обязательный параметр</h3>

              <div className={settingsList}>
                <div
                  className={settingsElement}
                  style={{
                    backgroundColor:
                      quantity > 0
                        ? "rgba(255, 255, 255, 0.15)"
                        : "rgba(255, 255, 255, 0.05)",
                  }}
                >
                  <h3>Количество</h3>
                  <div className={toggle}>
                    <TextField
                      id="shippingboxQuantity"
                      variant="standard"
                      type="number"
                      onFocus={(e) => e.target.value === "0" && setQuantity("")}
                      onChange={handleQuantity}
                      value={quantity}
                      sx={textFieldSx}
                      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    />
                  </div>
                </div>
              </div>

              <h3 className={titleSettings}>Дополнительные параметры</h3>

              <div className={additionalSettings}>
                <div
                  className={settingsElement}
                  style={{
                    backgroundColor: engravingText
                      ? "rgba(255, 255, 255, 0.15)"
                      : "rgba(255, 255, 255, 0.05)",
                  }}
                >
                  <h3>Гравировка</h3>
                  <div className={toggle}>
                    <TextField
                      fullWidth
                      id="acrylicEngravingText"
                      label="Кратко опишите"
                      variant="standard"
                      value={engravingText}
                      onChange={handleEngraving}
                      sx={textFieldSx}
                    />
                  </div>
                </div>
              </div>

              <h3 className={titleSettings}>Характеристики</h3>

              <div className={additionalSettings}>
                <div
                  className={settingsElement}
                  style={{ backgroundColor: "rgba(255, 255, 255, 0.15)" }}
                >
                  <h3>Размеры</h3>
                  <p>40*40*57см</p>
                </div>
                <div
                  className={settingsElement}
                  style={{ backgroundColor: "rgba(255, 255, 255, 0.15)" }}
                >
                  <h3>Фиксация диаметров</h3>
                  <p>
                    26,28,30,32,34,36,38см. <br />
                    Диаметр 40см - удерживается за счет внутренних стенок
                    короба.
                  </p>
                </div>
                <div
                  className={settingsElement}
                  style={{ backgroundColor: "rgba(255, 255, 255, 0.15)" }}
                >
                  <h3>Вес</h3>
                  <p>5,6кг</p>
                </div>
                <div
                  className={settingsElement}
                  style={{ backgroundColor: "rgba(255, 255, 255, 0.15)" }}
                >
                  <h3>Материал</h3>
                  <p>Фанера 6мм</p>
                </div>
                <div
                  className={settingsElement}
                  style={{ backgroundColor: "rgba(255, 255, 255, 0.15)" }}
                >
                  <h3>Дополнительно</h3>
                  <p>Разборная конструкция, удобно хранить</p>
                </div>
              </div>
            </div>

            <div className={orderInfo}>
              <h3>Ваши наборы</h3>
              {store?.shippingbox.totalPrice > 0 ? (
                <div className={productContainer}>
                  {store?.shippingbox.bundle.map((item, index) => {
                    return (
                      <div key={`order_${index}`} className={productItem}>
                        <button
                          className={delProduct}
                          aria-label="Удалить"
                          onClick={() => deleteProduct(index)}
                        >
                          <MdOutlineClose color="white" />
                        </button>
                        <h3>Набор {index + 1}</h3>
                        <p>{item.quantity} шт.</p>
                        {item.engravingText ? (
                          <p>С гравировкой</p>
                        ) : (
                          <p>Без гравировкой</p>
                        )}
                        <p>{item.totalAmount} руб.</p>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className={hint}>
                  <p>
                    Пока у Вас нет наборов.
                    <br />
                    <br />
                    Когда Вы выберите все обязательные параметры, кнопка внизу
                    покажет стоимость набора.
                    <br /> <br />
                    Вы можете формировать любое количество наборов, просто
                    продолжайте выбирать параметры.
                  </p>
                </div>
              )}
            </div>

            <div className={priceInfo}>
              <div className={mobileProducts}>
                <button onClick={() => setMobileList(!mobileList)}>
                  <MdShoppingBasket color="white" />
                  <p className={nubmer}>{store?.shippingbox.bundle.length}</p>
                </button>
                {mobileList && (
                  <Swiper
                    slidesPerView={"auto"}
                    spaceBetween={10}
                    grabCursor={true}
                    className={productContainer}
                  >
                    {store?.shippingbox.bundle.map((item, index) => {
                      return (
                        <SwiperSlide
                          key={`order_${index}`}
                          className={productItem}
                        >
                          <button
                            className="delProduct"
                            aria-label="Удалить"
                            onClick={() => deleteProduct(index)}
                          >
                            <MdOutlineClose color="white" />
                          </button>
                          <h3>Набор {index + 1}</h3>
                          <p>{item.quantity} шт.</p>
                          {item.engravingText ? (
                            <p>С гравировкой</p>
                          ) : (
                            <p>Без гравировкой</p>
                          )}
                          <p>{item.totalAmount} руб.</p>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                )}
              </div>
              {totalAmount > 0 ? (
                <button className={costBundle} onClick={addProduts}>
                  {totalAmount > 0
                    ? `Добавить набор ${totalAmount} руб.`
                    : "Сформируйте набор"}
                </button>
              ) : (
                <p className={costBundle}>Сформируйте набор</p>
              )}
              <Link to="/" className={mobCloseBtn}>
                <MdOutlineClose color="white" />
              </Link>
              <Link to="/" className={closeBtn}>
                {store?.shippingbox.bundle.length > 0
                  ? `Сохранить и закрыть`
                  : `закрыть`}
              </Link>
            </div>
          </div>
        </div>
      </motion.div>
    </main>
  );
}

export default Shippingbox;
